.ganttToolbarWrapper {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}

.ganttToolbarSelectField {
  min-width: 180px;
}

.ganttToolbarSelectsGroup {
  display: flex;

  & > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.ganttToolbarSelectAddOption {
  background-color: 'transparent';
  box-sizing: 'border-box';
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  border-top: 1px solid hsl(0, 0%, 80%);
  font-size: 0.8em;

  &:active {
    background-color: #b2d4ff;
  }

  &:hover {
    cursor: pointer;
  }

  &::before {
    color: var(--cyan);
  }
}

.ganttToolbarSelectLabel {
  display: initial;
  font-size: 0.9em;
}
